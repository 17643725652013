import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";
import NextLink from "@components/elements/Link";
import Button from "@src/components/elements/Button";
import NextImage from "@src/components/elements/Image";
import SmallChart from "@src/components/elements/SmallChart";
import { useInstrumentsApiRefresh } from "@utils/instrumentsApi";
import InstrumentPrice from "../hooks/InstrumentPrice";
import styles from "./TableRow.module.css";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import { useEffect } from "react";

export default function InstrumentsTableRow({ product_type, instrument, multipleInstruments }) {
  const activeRegistrationLink = useRegistrationLink();
  const { gv } = useGlobalVariable();
  const { api_id, symbol } = instrument.attributes;
  const instrumentPrice = useInstrumentsApiRefresh(!multipleInstruments ? symbol : multipleInstruments, 1000);
  const isIframe = useStore((s) => s.page_key, shallow)?.includes("iframe");
  //get url parameters is isIframe is true
  const urlParams =
    typeof window !== "undefined" && window?.location?.search && isIframe ? window?.location?.search : "";

  // const parameters = isIframe?
  // // send body height to parent
  // useEffect(() => {
  //   if (typeof window !== "undefined" && window && parent) {
  //     //add listener for resize event OR interval time,  and send the size to parent
  //     const interval = setInterval(() => {
  //       parent.postMessage(JSON.stringify({ name: 'iframe-instruments-table-popular', height: document.querySelector("body").scrollHeight }), '*');
  //     }, 250);
  //     return () => clearInterval(interval);
  //   }
  // }, []);

  const colorPulse = instrumentPrice?.currentData?.[symbol]?.change ? 
    (parseFloat(instrumentPrice?.currentData?.[symbol]?.change.replace(/[^\d.-]/g, "")) >= 0 ? `green` : `red`) : 'transparent';
  return (
    <div className="border-b border-[#ECECEC] last:border-none lg:border-none lg:[&:nth-child(2n+1)]:bg-[#f8f8f8]">
      <div>
        <ul className={`${styles.HomeInstrumentRow} relative`}>
          <li className="flex">
            {instrument?.attributes?.icon?.data?.attributes && (
              <div className="relative h-[1.65rem] w-[1.65rem] flex-[0_0_1.6rem] ltr:mr-xs rtl:ml-xs">
                <NextImage src={instrument?.attributes?.icon} layout="fill" objectFit="cover" />
              </div>
            )}
            <NextLink target={`${isIframe ? "_blank" : "_self"}`} href={instrument?.attributes?.slug + urlParams}>
              <a className="text-dark hover:text-secondary">
                {instrument?.attributes?.display_name || instrument?.attributes?.name}
              </a>
            </NextLink>
          </li>

          <li className="flex">
            <InstrumentPrice
              price={instrumentPrice?.currentData?.[symbol]}
              isActive={instrumentPrice?.currentData?.[symbol]?.isActive}
              buy
            />
          </li>

          {/* For CFD products we will show the buy price. */}
          {product_type === "CFD" && (
            <li className="flex">
              <InstrumentPrice
                price={instrumentPrice?.currentData?.[symbol]}
                isActive={instrumentPrice?.currentData?.[symbol]?.isActive}
                sell
              />
            </li>
          )}
          {instrumentPrice?.currentData?.[symbol]?.change && (
            <li
            className={`flex font-semibold ${parseFloat(instrumentPrice?.currentData?.[symbol]?.change) >= 0 ? `text-greenCandle` : `text-primary`
              } ${product_type === "CFD" ? `hidden lg:flex` : `flex`}
            `}
          >
            {instrumentPrice?.currentData?.[symbol]?.change}
          </li>
          )}
          <li className="hidden whitespace-nowrap font-semibold lg:flex">
            <SmallChart 
              // color={colorPulse} 
              instrumentId={symbol} 
              latestInstrumentPrice={instrumentPrice?.currentData?.[symbol]}
              />
          </li>
          <li className="hidden justify-end lg:flex">
            <Button
              urlParams={urlParams}
              link={activeRegistrationLink}
              size="sm"
              type="white-darkblue"
              label={gv("trade")}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
