import { motion } from "framer-motion";
import { Tabs } from "@mantine/core";
import Button from "@components/elements/Button";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import HomeGainersLoosers from "./components/HomeGainersLoosers";
import HomeInstrumentTable from "./components/HomeInstrumentsTable";
import { useEffect } from "react";

import styles from "./TradeHomeInstruments.module.css";
import InstrumentTable from "./components/InstrumentsTable";

// TO DO ADD DEBAUNCE onSearchChange in <MultiSelect />

const TradeHomeInstruments = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  useEffect(() => {
    if (typeof window !== "undefined" && window && parent) {
      //add listener for resize event OR interval time,  and send the size to parent
      const interval = setInterval(() => {
        parent.postMessage(
          JSON.stringify({
            name: "iframe-instruments-table-popular",
            height: document.querySelector(".instruments-table-container").scrollHeight,
          }),
          "*"
        );
      }, 250);
      return () => clearInterval(interval);
    }
  }, []);
  if (!data) return null;
  return (
    <div className="instruments-table-container overflow-hidden">
      <div className="container lg:tracking-wider">
        {(props.with_losers_gainers && false) ? (
          <Tabs
            classNames={{
              tabsListWrapper: styles.PrimarytabsListWrapper,
              tabLabel: styles.PrimarytabLabel,
              tabActive: styles.PrimarytabActive,
              tabControl: styles.PrimarytabControl,
            }}
          >
            <Tabs.Tab label={gv("popular_assets")}>
              <motion.div
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 100,
                  delay: 0.5,
                  damping: 15,
                }}
              >
                {/* <HomeInstrumentTable data={props} /> */}
                <InstrumentTable data={props} />
              </motion.div>
            </Tabs.Tab>
            <Tabs.Tab label={gv("gainers_losers")}>
              <motion.div
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <HomeGainersLoosers />
              </motion.div>
            </Tabs.Tab>
          </Tabs>
        ) : (
          <>
            {/* <HomeInstrumentTable data={props} /> */}
            <InstrumentTable data={props} />
          </>
        )}
      </div>
      {props?.bottom_button?.link && (
        <div className="flex justify-center pt-xl lg:pt-2xl">
          <Button data={props.bottom_button} />
        </div>
      )}
    </div>
  );
};

export default TradeHomeInstruments;
