import isNumber from "lodash/isNumber";

const getChartOptions = (chartData, gv, shouldAnimate) => {
  const getMaxDecimals = (data) => {
    if (!data || data.length === 0) return 2;

    let maxDecimals = 0;

    data.forEach((point) => {
      const value = point[1];
      if (isNumber(value)) {
        const strValue = value.toString();
        const decimalPart = strValue.includes(".") ? strValue.split(".")[1].length : 0;
        maxDecimals = Math.max(maxDecimals, decimalPart);
      }
    });

    return maxDecimals;
  };

  const valueDecimals = getMaxDecimals(chartData);
  const calculatedDecimals = valueDecimals;

  const minValue = chartData.reduce((min, point) => Math.min(min, point[1]), Infinity);
  const minWithMargin = minValue * 0.95;
  const maxValue = chartData.reduce((max, point) => Math.max(max, point[1]), -Infinity);
  const maxWithMargin = maxValue * 1.05;

  return {
    chart: {
      backgroundColor: "#242440", 
      borderRadius: 10,
      style: {
        fontFamily: "Inter, sans-serif",
      },
      animation: shouldAnimate
        ? {
            duration: 1000,
            easing: "easeOutQuart",
          }
        : false,
      height: 400,
      spacing: [20, 20, 20, 20],
    },
    colors: ["#7c63ff"], 
    rangeSelector: {
      enabled: false,
      buttons: [
        {
          type: "all",
          count: 1,
          text: "All",
        },
      ],
      selected: 0,
      inputEnabled: false,
    },
    series: [
      {
        name: gv("buy"),
        type: "areaspline",
        data: chartData,
        lineWidth: 2,
        marker: {
          enabled: true, 
          radius: 0.4, 
          symbol: "circle",
          fillColor: "#7c63ff",
          lineWidth: 2,
          lineColor: "#ffffff",
          borderRadius: 5,
          states: {
            hover: {
              enabled: true,
              radiusPlus: 5,
              lineWidth: 2,
              lineColor: "#ffffff",
              animation: {
                duration: 150,
              },
            },
            normal: {
              animation: {
                duration: 150,
              },
            },
          },
        },
        states: {
          hover: {
            lineWidth: 3,
            halo: {
              size: 1,
              opacity: 0.25,
              attributes: {
                fill: "#7c63ff",
              },
            },
          },
        },
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "rgba(124, 99, 255, 0.4)"],
            [1, "rgba(124, 99, 255, 0.05)"],
          ],
        },
        tooltip: {
          valueDecimals,
          animation: true,
          animationDuration: 200,
        },
        downLineColor: "#ff4848",
        upLineColor: "#58c914",
        dataGrouping: {
          units: [["minute", [1]]],
        },
        animation: shouldAnimate
          ? {
              duration: 1500,
              easing: "easeOutQuart",
            }
          : false,
        point: {
          events: {
            mouseOver(e) {
              const point = e.target;
              point.series.chart.tooltip.refresh(point);
            },
          },
        },
      },
    ],
    yAxis: {
      offset: 30,
      min: minWithMargin,
      max: maxWithMargin,
      gridLineColor: "rgba(255, 255, 255, 0.08)",
      gridLineWidth: 0.8,
      gridLineDashStyle: "Dash",
      labels: {
        style: {
          color: "#bdbdcf",
        },
      },
      title: {
        enabled: false,
      },
      crosshair: {
        color: "rgba(255, 255, 255, 0.35)",
        dashStyle: "Dash",
        width: 0.8,
        label: {
          enabled: true,
          backgroundColor: "rgba(36, 36, 64, 0.85)",
          borderWidth: 0,
          borderRadius: 4,
          style: {
            color: "#ffffff",
            fontWeight: "500",
            fontSize: "10px",
            padding: "2px 6px",
          },
          format: `{value:.${calculatedDecimals}f}`,
        },
      },
    },
    xAxis: {
      lineColor: "rgba(255, 255, 255, 0.15)",
      gridLineColor: "rgba(255, 255, 255, 0.08)", 
      gridLineWidth: 0.8,
      gridLineDashStyle: "Dash",
      labels: {
        style: {
          color: "#bdbdcf",
        },
      },
      crosshair: {
        color: "rgba(255, 255, 255, 0.35)",
        dashStyle: "Dash",
        width: 0.8,
        label: {
          enabled: true,
          backgroundColor: "rgba(36, 36, 64, 0.85)",
          borderWidth: 0,
          borderRadius: 4,
          style: {
            color: "#ffffff",
            fontWeight: "500",
            fontSize: "10px",
            padding: "2px 6px",
          },
        },
      },
    },
    tooltip: {
      backgroundColor: "rgba(36, 36, 64, 0.9)",
      borderWidth: 0,
      shadow: true,
      style: {
        color: "#ffffff",
        fontSize: "12px",
      },
      animation: true,
      shared: true,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        animation: shouldAnimate,
        threshold: null,
        marker: {
          enabled: true,
          radius: 3,
        },
        stickyTracking: true,
      },
      series: {
        turboThreshold: 0,
        animation: {
          duration: 1000,
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
  };
};

export default getChartOptions;
