import Head from "next/head";

export const getFullUrl = (slug, locale) => {
  const url = slug && slug?.includes("https://capex.com") ? slug : `https://capex.com/${locale}${slug && slug !== "/" ? slug : ""}`;
  const filterUrl = url?.includes(`/${locale}/${locale}`) ? url.replace(`/${locale}/${locale}/`, `/${locale}/`) : url;
  const finalUrl = `${filterUrl !== "/" ? filterUrl : ""}`;
  return finalUrl;
};

const AlternateTagStrict = ({ hrefLang, locale, slug }) => {
  const finalUrl = getFullUrl(slug, locale);
  return (
    <Head>
      <link rel="alternate" hrefLang={hrefLang} href={finalUrl} />
    </Head>
  );
};

export default function AlternatesSection({ pageType, localizationVersions, locale }) {
  if (["page", "legal_document", "faq", "ipo", "webinar", "instruments"].includes(pageType)) {
    return (
      <>
        {/* {localizationVersions?.ae && (
          <AlternateTagStrict hrefLang="en-ae" slug={localizationVersions?.ae} locale="ae" />
        )}
        {localizationVersions?.za && (
          <AlternateTagStrict hrefLang="en-za" slug={localizationVersions?.za} locale="za" />
        )}
        {localizationVersions?.eu && (
          <AlternateTagStrict hrefLang="en-cy" slug={localizationVersions?.eu} locale="eu" />
        )}
        {localizationVersions?.eu && (
          <AlternateTagStrict hrefLang="en-gb" slug={localizationVersions?.eu} locale="eu" />
        )} */}
        {/* {localizationVersions?.["ae-ar"] && (
          <>
            <AlternateTagStrict hrefLang="ar-ae" slug={localizationVersions?.["ae-ar"]} locale="ae-ar" />
            <AlternateTagStrict hrefLang="ar-sa" slug={localizationVersions?.["ae-ar"]} locale="ae-ar" />
          </>
        )} */}
        {localizationVersions?.ar && <AlternateTagStrict hrefLang="ar" slug={localizationVersions?.ar} locale="ar" />}
        {/* {localizationVersions?.es && <AlternateTagStrict hrefLang="es" slug={localizationVersions?.es} locale="es" />} */}
        {localizationVersions?.lat && (
          <AlternateTagStrict hrefLang="es-ec" slug={localizationVersions?.lat} locale="lat" />
        )}
        {/* {localizationVersions?.it && <AlternateTagStrict hrefLang="it" slug={localizationVersions?.it} locale="it" />}
        {localizationVersions?.vn && <AlternateTagStrict hrefLang="vi" slug={localizationVersions?.vn} locale="vn" />}
        {localizationVersions?.ro && <AlternateTagStrict hrefLang="ro" slug={localizationVersions?.ro} locale="ro" />}
        {localizationVersions?.de && <AlternateTagStrict hrefLang="de" slug={localizationVersions?.de} locale="de" />}
        {localizationVersions?.cz && <AlternateTagStrict hrefLang="cs" slug={localizationVersions?.cz} locale="cz" />} */}
        {localizationVersions?.en && <AlternateTagStrict hrefLang="en" slug={localizationVersions?.en} locale="en" />}
        {/* {localizationVersions?.el && <AlternateTagStrict hrefLang="el" slug={localizationVersions?.el} locale="el" />} */}
        {localizationVersions?.en && (
          <AlternateTagStrict hrefLang="x-default" slug={localizationVersions?.en} locale="en" />
        )}
        {!localizationVersions?.en && (
          <AlternateTagStrict hrefLang="x-default" slug={localizationVersions?.[locale]} locale={locale} />
        )}
      </>
    );
  }

  if (["en", "eu", "za", "ae"].includes(locale) && ["article", "academy"].includes(pageType)) {
    return (
      <>
        {localizationVersions?.en && <AlternateTagStrict hrefLang="en" slug={localizationVersions?.en} locale="en" />}
        {/* {localizationVersions?.ae && (
          <AlternateTagStrict hrefLang="en-ae" slug={localizationVersions?.ae} locale="ae" />
        )} */}
        {/* {localizationVersions?.za && (
          <AlternateTagStrict hrefLang="en-za" slug={localizationVersions?.za} locale="za" />
        )} */}
        {/* {localizationVersions?.eu && (
          <>
            <AlternateTagStrict hrefLang="en-gb" slug={localizationVersions?.eu} locale="eu" />
            <AlternateTagStrict hrefLang="en-cy" slug={localizationVersions?.eu} locale="eu" />
          </>
        )} */}
        {localizationVersions?.en && (
          <AlternateTagStrict hrefLang="x-default" slug={localizationVersions?.en} locale="en" />
        )}
        {!localizationVersions?.en && (
          <AlternateTagStrict hrefLang="x-default" slug={localizationVersions?.[locale]} locale={locale} />
        )}
      </>
    );
  }
  if (["es", "lat"].includes(locale) && ["article", "academy"].includes(pageType)) {
    return (
      <>
        {/* {localizationVersions?.es && (
          <AlternateTagStrict hrefLang="es-es" slug={localizationVersions?.es} locale="es" />
        )} */}
        {localizationVersions?.lat && (
          <AlternateTagStrict hrefLang="es" slug={localizationVersions?.lat} locale="lat" />
        )}
        {localizationVersions?.lat && (
          <AlternateTagStrict hrefLang="x-default" slug={localizationVersions?.lat} locale="lat" />
        )}
        {/* {!localizationVersions?.lat && (
          <AlternateTagStrict hrefLang="x-default" slug={localizationVersions?.lat} locale="lat" />
        )} */}
      </>
    );
  }
  if (["ar", "ae-ar"].includes(locale) && ["article", "academy"].includes(pageType)) {
    return (
      <>
        {localizationVersions?.ar && <AlternateTagStrict hrefLang="ar" slug={localizationVersions?.ar} locale="ar" />}
        {/* {localizationVersions?.["ae-ar"] && (
          <>
            <AlternateTagStrict hrefLang="ar-ae" slug={localizationVersions?.["ae-ar"]} locale="ae-ar" />
            <AlternateTagStrict hrefLang="ar-sa" slug={localizationVersions?.["ae-ar"]} locale="ae-ar" />
          </>
        )} */}
        {localizationVersions?.ar && (
          <AlternateTagStrict hrefLang="x-default" slug={localizationVersions?.ar} locale="ar" />
        )}
        {!localizationVersions?.ar && (
          <AlternateTagStrict hrefLang="x-default" slug={localizationVersions?.[locale]} locale={locale} />
        )}
      </>
    );
  }

  return null;
}
