import { useState, useMemo, useEffect } from "react";
import { Box, Center, LoadingOverlay } from "@mantine/core";
import instrumentsApi, { useInstrumentsChartApi } from "@utils/instrumentsApi";
import Button from "@components/elements/Button";
import dynamic from "next/dynamic";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";
import getChartOptions  from "./components/chartOptions";
import styles from "./InstrumentChart.module.css";

const CustomHighcharts = dynamic(() => import("./components/CustomHighstock"), {
  ssr: true,
});

const InstrumentChart = (props) => {
  const registrationLink = useRegistrationLink();
  const { gv } = useGlobalVariable();
  const [resolution, setResolution] = useState("7d");
  const [initialRender, setInitialRender] = useState(true);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const [isResolutionChange, setIsResolutionChange] = useState(false);
  const instrumentPrice = instrumentsApi(props.instrumentId, 3000);
  const sell = instrumentPrice?.currentData?.[`${props.instrumentId}`]?.sell;
  const buy = instrumentPrice?.currentData?.[`${props.instrumentId}`]?.buy;

  const { transformedData, isLoading } = useInstrumentsChartApi(
    {
      symbol: props.instrumentId,
      resolution,
      candleType: "d",
    },
    {
      refreshInterval: 30000,
    },
    instrumentPrice?.currentData?.[`${props.instrumentId}`]
  );

  const chartData = useMemo(
    () => (transformedData && transformedData.length > 0 ? transformedData : []),
    [transformedData]
  );

  const handleResolutionChange = (newResolution) => {
    if (newResolution !== resolution) {
      setIsResolutionChange(true);
      setShouldAnimate(true);
      setResolution(newResolution);
    }
  };

  useEffect(() => {
    if (initialRender && chartData.length > 0) {
      setInitialRender(false);
      setShouldAnimate(true);
    }
  }, [chartData, initialRender]);

  useEffect(() => {
    if ((shouldAnimate || isResolutionChange) && chartData.length > 0 && !isLoading) {
      const timer = setTimeout(() => {
        setShouldAnimate(false);
        setIsResolutionChange(false);
      }, 2000); 
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [shouldAnimate, isResolutionChange, chartData, isLoading]);

  const options = useMemo(
    () => getChartOptions(chartData, gv, shouldAnimate),
    [chartData, gv, shouldAnimate]
  );

  if (buy === undefined && sell === undefined) {
    return null;
  }

  return (
    <section>
      <div className="container">
        <div className="pb-xl lg:grid lg:grid-cols-3 lg:gap-10">
          <div className="lg:col-span-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className={`flex items-center rounded-md p-1 ${styles.resolution} pb-3`}>
                  {["7d", "30d", "6m", "1y"].map((resolution_item, index) => (
                    <div key={index}>
                      <button
                        type="button"
                        className={`px-3 py-1.5 text-sm font-medium transition-all duration-200 rounded-md ${
                          resolution_item === resolution
                            ? "shadow-sm bg-[#e5e5e6]"
                            : " hover:bg-[#e5e5e6]"
                        }`}
                        onClick={() => {
                          handleResolutionChange(resolution_item);
                        }}
                      >
                        {resolution_item}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              {props.instrumentName && (
                <p className="ml-md hidden text-sm uppercase md:block">{props.instrumentName}</p>
              )}
            </div>
            <div className="max-w-full shadow-md rounded-md">
              <Box sx={{ height: "400px", position: "relative" }}>
                <LoadingOverlay visible={isLoading} overlayBlur={2} />
                {!chartData || (chartData.length === 0 && !isLoading) ? (
                  <Center style={{ height: "100%" }}>
                    <p className="text-gray-600">...</p>
                  </Center>
                ) : (
                  !isLoading && <CustomHighcharts options={options} />
                )}
              </Box>
            </div>
            <div className="mt-4 text-center">
              <Button
                type="link"
                link="/registration"
                size="md"
                className="btn-secondary"
                label={gv("view_advanced_charts")}
              />
            </div>
          </div>
          <div
            className={`flex flex-col  pt-lg md:items-center lg:col-span-1 lg:block lg:pt-xl ${
              styles[`trade-buttons`]
            }`}
          >
            <div className="pb-md">
              <Button
                type="primary"
                link={registrationLink}
                labelPos="below"
                size="lg"
                arrow="right"
                label={gv("sell_button_with_price", {
                  template: {
                    price: sell,
                  },
                })}
                subLabel={gv("start_trading_now")}
              />
            </div>
            <div className="pb-xl lg:pb-2xl">
              <Button
                type="green"
                link={registrationLink}
                labelPos="below"
                size="lg"
                arrow="right"
                label={gv("buy_button_with_price", {
                  template: {
                    price: buy,
                  },
                })}
                subLabel={gv("start_trading_now")}
              />
            </div>
            <p className="text-center text-sm ltr:lg:text-left rtl:lg:text-right">{gv("past_performance")}</p>
          </div>
        </div>
        {props.instrumentName && (
          <div className="pb-xl text-center">
            <p className="mb-lg">
              {gv("instrument_page_trade_online_at", {
                template: {
                  instrument_name: props?.instrumentName,
                },
              })}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default InstrumentChart;
