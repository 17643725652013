import styles from "./instruments.module.css";
import Link from "@components/elements/Link";
import InstrumentTradingConditions from "@components/custom/InstrumentTradingConditions";
import InstrumentRelatedArticles from "@components/custom/InstrumentRelatedArticles";
import InstrumentInfo from "@components/custom/InstrumentInfo";
import InstrumentTrends from "@components/custom/InstrumentTrends";
import InstrumentChart from "@components/custom/InstrumentChart";
import InstrumentHeader from "@components/custom/InstrumentHeader";
import Breadcrumbs from "@components/custom/Breadcrumbs";
import InstrumentContent from "@src/components/custom/InstrumentContent";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { Accordion } from "@mantine/core";
import { HiOutlineChevronDown } from "@react-icons/all-files/hi/HiOutlineChevronDown";
import HTMLRender from "@src/components/core/HTMLRender";
import { FAQPageJsonLd } from "next-seo";
import htmlToCleanText from "@utils/html-to-text";
import { useIntersection } from "@mantine/hooks";
import { useEffect, useRef } from "react";

export default function LayoutInstruments({ pageData, pageType }) {
  const instrumentUniqueKey = pageData?.symbol;
  const instrumentId = pageData?.id;
  const { gv } = useGlobalVariable();

  const containerRef = useRef();
  const [ref, observedEntry] = useIntersection({
    root: containerRef.current,
    threshold: 0,
  });
  const inView = observedEntry && observedEntry.isIntersecting;

  useEffect(() => {
    const body = document.querySelector('body');
    if (!inView) {
      body.classList.add('show-sticky');
    } else {
      body.classList.remove('show-sticky');
    }
  }, [inView])

  if (pageType !== "instruments") return null;

  return (
    <>
      {pageData?.faq_list && pageData?.faq_list?.length > 0 &&
        <FAQPageJsonLd
          mainEntity={
            pageData?.faq_list?.map(item => (
              {
                questionName: htmlToCleanText(item.title),
                acceptedAnswerText: htmlToCleanText(item.content)
              }
            ))
          }
        />
      }
      {/* Instrument BreadCrumbs */}
      <div ref={ref} className="lg:pt-[4.8888rem]" />
      {/* <Breadcrumbs pageData={pageData} /> */}
      <InstrumentHeader data={pageData} />
      <InstrumentChart instrumentId={instrumentUniqueKey} instrumentName={pageData?.display_name} />
      {/* <InstrumentTrends instrumentId={instrumentUniqueKey} /> */}
      {/* <InstrumentInfo data={pageData} /> */}
      <InstrumentRelatedArticles instrumentUniqueKey={instrumentUniqueKey} instrumentId={instrumentId} />
      <InstrumentTradingConditions data={pageData} />
      <InstrumentContent data={pageData} />
      {/* instruments faq section */}
      {pageData?.faq_list && pageData?.faq_list.length > 0 &&
        <div className="container mt-[-8rem] mb-xl">
          {
            gv('intrument_faq_title') !== 'intrument_faq_title' &&
            <div className="mt-2xl text-center">
              <h2>{gv('intrument_faq_title')}</h2>
            </div>
          }
          <div className="py-xl">
            <Accordion
              styles={{ icon: { color: '#000' } }}
              classNames={{
                control: styles.AccordionControl,
                root: styles.AccordionRoot,
                itemOpened: styles.itemOpened,
                label: styles.AccordionLabel,
                item: styles?.AccordionItem,
                contentInner: styles?.contentInner,
                icon: styles.borderAccordionIcon,
                content: styles?.AccordionContent,
              }}
              variant="unstyled"
              transitionDuration={500}
              icon={<HiOutlineChevronDown />}
              iconPosition="right"
            >
              {pageData?.faq_list?.map((question, index) => (
                <Accordion.Item key={index} label={<HTMLRender data={question?.title} />}>
                  <HTMLRender data={question?.content} />
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      }
    </>
  );
}
