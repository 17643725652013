import { useRef } from "react";
import useSWR from "swr";
import qs from "qs";
import useLocale from "@lib/store/hooks/useLocale";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function useInstrumentApiIn(list, category, isVisible = true) {
  const val = useRef();
  const locale = useLocale();
  const query = {
    populate: "*",
    fields: ["api_id", "display_name", "slug", "id", "symbol"],
    locale,
    filters: {
      api_id: {
        $in: list,
      },

      ...(category && {
        global_instrument_types: {
          name: {
            $eq: category,
          },
        },
      }),
    },
    sort: ["publishedAt:desc"],
  };

  const queryStr = qs.stringify(query);
  const { data, isValidating, error, ...rest } = useSWR(
    `${process.env.NEXT_PUBLIC_API_STRAPI}/api/instruments?${queryStr}`,
    fetcher,
    {
      refreshWhenOffline: false,
      revalidateOnReconnect: true,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshInterval: false,
    }
  );
  if (data !== undefined) {
    val.current = data;
  }

  if (isVisible) {
    return {
      ...rest,
      isValidating,
      error,
      data: val.current,
    };
  } else {
    return {};
  }
}
