import { Fragment, useEffect, useRef, useState } from "react";
import InstrumentCategoriesTabs from "../InstrumentCategoriesTabs";
import InstrumentsTableHeader from "../InstrumentsTableHeader";
import SearchInstruments from "./components/SearchInstruments";
import InstrumentsTableRow from "./components/TableRow";
import useInstrumentApiIn from "./hooks/useInstrumentApiIn";
import useInstrumentCategoriesTabs from "./hooks/useInstrumentCategoriesTabs";

export default function InstrumentTable(props) {
  const defaultInstruments = props?.data?.qd_instruments?.data?.map((item) => item?.symbol);

  // Extract subcategories from qd_instrument_categories
  const subcategories = props?.data?.qd_instruments && [...new Set(props?.data?.qd_instruments?.data?.map(category => {
    return category?.global_instrument_types?.find(type => type?.category_type === "subcategory")?.name;
  })?.filter(name => name !== undefined))] || [];

  const withTabs = props?.data?.table_type === "with_tabs_from_categories";
  const product_type = props?.data?.product_type;
  const [activeCategory, setActiveCategory] = useState(null);
  const { category, activeTab, changeTab } = useInstrumentCategoriesTabs(withTabs, subcategories[0]);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setIsVisible(entries[0].isIntersecting);
      },
      {
        threshold: 0 // fetch instrument table immediately as it becomes in view
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (withTabs) {
      setActiveCategory(category ? category : subcategories[0])
    } else {
      setActiveCategory(null)
    }
  }, [withTabs, activeTab, changeTab, subcategories, defaultInstruments]);

  const [instrumentSearchSelectedList, setInstrumentSearchSelectedList] = useState([]);
  const instrumentApiIdList = instrumentSearchSelectedList.length ? instrumentSearchSelectedList : defaultInstruments;
  const { data: instrumentData } = useInstrumentApiIn(instrumentApiIdList, activeCategory, isVisible);
  const allApiIds = [];

  if (instrumentData && instrumentData.data.length > 0) {
    instrumentData.data.forEach(item => {
      if (item.attributes && item.attributes.symbol) {
        allApiIds.push(item.attributes.symbol);
      }
    });
  }

  if (!props?.data) return null;

  return (
    <div ref={containerRef}>
      <SearchInstruments
        category={category}
        search={instrumentSearchSelectedList}
        setSearch={setInstrumentSearchSelectedList}
        withTabs={withTabs}
      />
      <InstrumentCategoriesTabs data={props.data} activeTab={activeTab} changeTab={changeTab} />
      <InstrumentsTableHeader product_type={product_type} />
      {instrumentData?.data?.map((instrument) => (
        <InstrumentsTableRow multipleInstruments={allApiIds} product_type={product_type} instrument={instrument} key={instrument.api_id} />
      ))}
    </div>
  );
}
