import styles from './InstrumentHeader.module.css'
import instrumentsApi from '@utils/instrumentsApi';
import Arrow from '@components/elements/Arrow';
import HTMLRender from '@components/core/HTMLRender';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';

const InstrumentHeader = (props) => {
    const data = props.data || props;
    const { gv } = useGlobalVariable();
    if (!data) {
        return null;
    }
    const instrumentId = data?.symbol;
    const instrumentPrice = instrumentsApi(instrumentId, 10000);
    let instrumentTrend = parseFloat(instrumentPrice.currentData?.[`${instrumentId}`]?.change) < 0 ? "down" : "up";
    let price = instrumentPrice?.currentData?.[`${instrumentId}`]?.buy;
    let change = instrumentPrice?.currentData?.[`${instrumentId}`]?.change;


    return (
        <section>
            <div className="container">
                <div className={`pt-3xl pb-xl lg:pt-2xl lg:pb-md text-center ${styles[`instrument-title`]}`}>
                    {(data?.description || data?.display_name) &&
                        props.heading !== "h2" ?
                        <h1 className="mb-lg">
                            {data?.description !== null ?
                                <HTMLRender data={data?.description} />
                                : <span>{data?.display_name}</span>
                            }
                        </h1>
                        :
                        <h2 className="mb-lg">
                            {data?.description !== null ?
                                <HTMLRender data={data?.description} />
                                : <span>{data?.display_name}</span>
                            }
                        </h2>
                    }
                    {data?.display_name &&
                        <p className="mb-lg">{gv('instrument_page_subtitle', {
                            template: {
                                instrument_name: data?.display_name
                            }
                        })}</p>
                    }

                    <div className={`${styles[`instrument-price`]}`}>
                        <div className={instrumentTrend == "up" ? `text-greenCandle` : `text-primary`}>
                            <div className="flex items-center">
                                <h3 className="m-0">{price}</h3>
                                <div className="px-lg text-h3">
                                    <Arrow direction={instrumentTrend} />
                                </div>
                                <h5 className="m-0">{change}</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default InstrumentHeader;
